import React from 'react'
import { Input, Table, message } from 'antd';
// import { PlusOutlined, ArrowRightOutlined, PhoneOutlined, TeamOutlined } from  '@ant-design/icons'
import { reqBookTopList } from '../../api/index'


import './stat.less'


const { Search } = Input

// 规则路由
export default class Stat extends React.Component {

    state = {
        classRoomList: [],
        showClassRoomList: undefined,

        filteredInfo: null,
        sortedInfo: null,
    }

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
          filteredInfo: filters,
          sortedInfo: sorter,
        });
      };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
      };

    clearAll = () => {
    this.setState({
        filteredInfo: null,
        sortedInfo: null,
    });
    };

    setAgeSort = () => {
        this.setState({
          sortedInfo: {
            order: 'descend',
            columnKey: 'age',
          },
        });
      };

    handleCancel = () => {
        // 清除输入数据
        console.log("取消")
        this.form.resetFields()
        this.setState({
            showStatus: 0
        })
    }

    onChange = (e) => {
        console.log(333, e.target.value)
        let input = e.target.value.trim()
        const classRoomList = this.state.classRoomList

        if(input === "") {
            this.setState({
                showClassRoomList: classRoomList
            })
            return
        }

        const filteredList = classRoomList.filter((item, key) => {
            const name = item.name;
            const author = item.author;
            return name.indexOf(input) !== -1 || author.indexOf(input) !== -1;
          });

        this.setState({
            showClassRoomList: filteredList
        })
    }

    getClassRoomList = async () => {
        const result = await reqBookTopList()
        console.log("result", result)
        if(result.status===0){
            this.setState({
                        classRoomList: result.data,
                        showClassRoomList: result.data
                    })
        } else {
            message.error(result.msg)
        }
    }

    componentDidMount = () => {
        this.getClassRoomList()
    }

    render() {
        const { showClassRoomList } = this.state

        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: '书名',
                dataIndex: 'name',
                width: 300
            },
            {
                title: '作者',
                dataIndex: 'author',
                width: 150
            },
            {
                title: '已读次数',
                dataIndex: 'read_count',
                width: 80,
                sorter: (a, b) => a.read_count - b.read_count,
                sortOrder: sortedInfo.columnKey === 'read_count' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: '在读次数',
                dataIndex: 'reading_count',
                width: 80,
                sorter: (a, b) => a.reading_count - b.reading_count,
                sortOrder: sortedInfo.columnKey === 'reading_count' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: '想读次数',
                dataIndex: 'to_read_count',
                width: 100,
                sorter: (a, b) => a.to_read_count - b.to_read_count,
                sortOrder: sortedInfo.columnKey === 'to_read_count' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: '重复读次数',
                dataIndex: 'repeat_count',
                width: 100,
                sorter: (a, b) => a.repeat_count - b.repeat_count,
                sortOrder: sortedInfo.columnKey === 'repeat_count' && sortedInfo.order,
                ellipsis: true,
            },
            
        ]

        return (
            <div>
                <Search
                    placeholder="输入书籍名称或作者搜索"
                    onChange={this.onChange}                    
                    allowClear
                    style={{ width: 300, marginRight: 20, marginBottom: 20}}
                    />
                <Table 
                    bordered={false}
                    dataSource={showClassRoomList} 
                    rowKey="id" 
                    columns={columns}
                    onChange={this.handleChange}                    
                    pagination={{defaultPageSize: 20, showQuickJumper: true, showSizeChanger: true}}
                />
            </div>
        )
    }
}
