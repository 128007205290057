import React from 'react'
import ReactDOM from 'react-dom'
// antd国际化，设置为中文
import { ConfigProvider } from 'antd'
import zh_CN from 'antd/es/locale-provider/zh_CN';

import App from './App'
import memoryUtils from './utils/memoryUtils'
import storageUtils from './utils/storageUtils'
import './index.less'



// 读取local中保存的user, 保存到内存中
const user = storageUtils.getUser()
memoryUtils.user = user

ReactDOM.render(<ConfigProvider locale={zh_CN}><App /></ConfigProvider>, document.getElementById('root'))