import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Button, Icon } from 'antd';

import  {formatDate} from '../../utils/dateUtils'
import  memoryUtils from '../../utils/memoryUtils'
import  torageUtils from '../../utils/storageUtils'
import {reqWeather} from '../../api/index'
import menuList from '../../config/menuConfig.js'

import LinkBotton from '../link-button/'

 
import './index.less'


const { confirm } = Modal;


// 左侧导航的组件
class Header extends Component {

    

    // 退出登录
    logout = () => {
        // 显示确认框
        confirm({
            // icon: <ExclamationCircleOutlined />,
            content: '确认退出吗？',
            onOk: () => {
                // 删除保存的user数据
                torageUtils.removeUser()
                memoryUtils.user = {}
                // 跳转到login界面
                this.props.history.replace('/login')
            },
            onCancel() {
                console.log('Cancel');
            },
            });

    }

    render() {
        // const {dayPictureUrl, weather, currentTime} = this.state

        const user_name = memoryUtils.user.user_name
        // 得到当前需要显示的title
        // const title = this.getTitle()

        return (
            <div className="header-top">
                <div>
                    <Icon
                        className="trigger"
                        type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={this.props.toggle}
                        />
                    <span>Hi, {user_name}</span>
                </div>

                <Button  className="header-button" size="small" onClick={this.logout}>退出</Button>
            </div>
        )
    }
}


export default withRouter(Header)