import React from 'react'
import { reqUserBookList } from '../../api/index'
import PropTypes from 'prop-types'
import { Table, message, Tag } from 'antd'

import './user.less'


export default class UserDetail extends React.Component {

    static proTypes = {
        openid: PropTypes.object.isRequired,
    }

    state = {
        books: [],
        openid: "",
        reading: 0,
        try_read: 0,
        read: 0,
    }

    getUserBookList = async (openid) => {
        const result = await reqUserBookList(openid)
        if(result.status===0){
            let reading = 0, try_read = 0, read = 0
            result.data.map((item, key) => {
                if(item.book_status==0){
                    reading += 1
                } else if(item.book_status==1){
                    try_read += 1
                } else if(item.book_status==2){
                    read += 1
                }
            })
            this.setState({
                books: result.data,
                reading,
                try_read,
                read
            })
        } else {
            message.error('获取用户书籍列表失败')
        }
    }

    componentDidMount() {
        // const openid = this.stats.openid
    }

    componentWillMount() {
        // 将form对象通过setForm方法传递给父组件
        // this.props.setForm(this.props.form)
        const {openid} = this.props
        // console.log('openid', openid)

        this.getUserBookList(openid)
        this.setState({
            openid
        })


    }

    status = {
        0: ['在读', 'green'],
        1: ['想读', 'orange'],
        2: ['已读', 'gray']
    }

    render() {
        const { books, reading, try_read, read } = this.state

        const columns = [
            {
                title: '书名',
                dataIndex: 'name',
                width: 130
            },
            {
                title: '重复次数',
                dataIndex: 'repeat_count',
                width: 40
            },
            {
                title: '首次阅读日期',
                dataIndex: 'book_started_date',
                width: 60
            },
            {
                title: '结束日期',
                dataIndex: 'book_completed_date',
                width: 60
            },
            {
                title: '状态',
                width: 40,
                render: (a, record) => (<Tag color={this.status[record.book_status][1]}>{this.status[record.book_status][0]}</Tag>),

            },
            {
                title: '添加时间',
                dataIndex: 'book_created_date',
                width: 100
            }
        ]


        return (
            <div className='userdetail'>
                <div style={{marginBottom: 20}}>
                    <Tag color="green">在读{reading}</Tag>
                    <Tag color="orange">想读{try_read}</Tag>
                    <Tag color="gray">已读{read}</Tag>
                </div>
                <Table 
                    bordered={false}
                    dataSource={books} 
                    rowKey="id" 
                    columns={columns}
                    // onChange={this.handleChange}
                    pagination={{defaultPageSize: 10, showQuickJumper: true, showSizeChanger: true}}
                />
            </div>

        );
    }
}