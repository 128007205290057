import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Icon } from 'antd';

import './index.less'
import menuList from '../../config/menuConfig'
import logo from '../../assets/images/logo.png'



const { SubMenu } = Menu;

// 左侧导航的组件
class LeftNav extends Component {

    getMenuNodes_map = (menuList) => {
        // 根据menu的数据数组生产对应的标签数组
        // 使用map() + 递归调用
        return menuList.map(item => {
            /*
                {
                    title: '首页', // 菜单标题名称
                    key: '/home', // 对应的path
                    icon: 'home', // 图标名称
                }
            */


            /*
            {
                title: '商品',
                key: '/products',
                icon: 'appstore',
                children: [ // 子菜单列表
                    {
                        title: '品类管理',
                        key: '/category',
                        icon: 'bars'
                    },
                    {
                        title: '商品管理',
                        key: '/product',
                        icon: 'tool'
                    },
                ]
            }
            */
            if (!item.children) {
                return (
                    <Menu.Item key={item.key}>
                        <Link to={item.key}>
                            <Icon type={item.icon} />
                            <span>{item.title}</span>
                        </Link>
                    </Menu.Item>
                )
            } else {
                return (
                    <SubMenu
                    key={item.key}
                    title={
                        <span>
                            <Icon type={item.icon} />
                            <span>{item.title}</span>
                        </span>
                    }
                >
                    {this.getMenuNodes(item.children)}
                </SubMenu>
                )
            }
        })
    }

    getMenuNodes = (menuList) => {

        // 得到当前请求的路由路径
        const path = this.props.location.pathname

        // 根据menu的数据数组生产对应的标签数组
        // 使用reducr() + 递归调用
        return menuList.reduce((pre, item) => {
            // 向pre中添加<Menu.Item>
            // 向pre中添加<SubMenu>
            if(!item.children) {
                pre.push(
                    <Menu.Item key={item.key}>
                    <Link to={item.key}>
                        <Icon type={item.icon} />
                        <span>{item.title}</span>
                    </Link>
                </Menu.Item>
                )
            } else {
                // 查找一个与当前请求路径匹配的子Item
                const cItem = item.children.find(cItem => cItem.key===path)
                // 如果存在,说明当前item的子列表需要打开
                if(cItem) {
                    this.openKey = item.key
                }

                pre.push(
                    <SubMenu
                    key={item.key}
                    title={
                        <span>
                            <Icon type={item.icon} />
                            <span>{item.title}</span>
                        </span>
                    }
                >
                    {this.getMenuNodes(item.children)}
                </SubMenu>
                )
            }

            return pre
        }, [])
    } 

    // 在第一次render()之前
    // 为第一次render()准备数据(同步的)
    componentWillMount() {
        this.menuNodes = this.getMenuNodes(menuList)
    }

    render() {

        // 得到当前请求路径
        const path = this.props.location.pathname
        const fontSize = this.props.fontSize ? 0 : 15

        return (
            <div className='left-nav'>
                <Link to='/' className='left-nav-header'>
                    <div className='imgDiv'>
                        <img src={logo} alt='logo' />
                    </div>
                    
                    {
                        fontSize? <h1 style={{fontSize: fontSize}}>享读打卡</h1>:""
                    }
                    
                </Link>

                <Menu
                    className='left-nav-menu'
                    mode="inline"
                    theme="dark"
                    selectedKeys={[path]}
                    defaultOpenKeys={[this.openKey]}>
                    
                    {
                        this.menuNodes
                    }

                </Menu>
            </div>
        )
    }
}

/* 
withRouter高阶组件:
    包装非路由组件,返回一个新的组件
    新的组件向非路由组件传递3个属性: history/location/match

*/
export default withRouter(LeftNav)