import React from 'react'
import { Input, Table, message, Modal, Button } from 'antd';
// import { PlusOutlined, ArrowRightOutlined, PhoneOutlined, TeamOutlined } from  '@ant-design/icons'
import { reqAllUser } from '../../api/index'
import UserDetail from './user-detail'
import './user.less'



const { Search } = Input

// 规则路由
export default class User extends React.Component {

    state = {
        storeList: [],
        showStatus: 0, // 0显示主页面，1显示新增门店，2显示修改门店
        confirmLoading: false,

        users: [],
        showUsers: undefined,
        filteredInfo: null,
        sortedInfo: null,
    }

    classRoomStatusMap = [
        {
            'id': 0,
            'title': '停用',
        },
        {
            'id': 1,
            'title': '启用',
        }
    ]

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    onChange = (e) => {
        let input = e.target.value.trim()
        const users = this.state.users

        if (input === "") {
            this.setState({
                showUsers: users
            })
            return
        }

        const filteredList = users.filter((item, key) => {
            const openid = item.openid;
            const nickName = item.nickName;
            if (nickName === undefined) {
                return openid.indexOf(input) !== -1
            } else if (nickName !== null) {
                return openid.indexOf(input) !== -1 || nickName.indexOf(input) !== -1;
            } else {
                return false
            }

        });

        console.log("filteredList", filteredList)

        this.setState({
            showUsers: filteredList
        })
    }

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };


    handleCancel = () => {
        // 清除输入数据
        console.log("取消")
        // this.form.resetFields()
        this.setState({
            showStatus: 0
        })
    }

    getAllUser = async () => {
        const result = await reqAllUser()
        if (result.status === 0) {
            this.setState({
                users: result.data,
                showUsers: result.data,
            })
        } else {
            message.error('获取用户列表失败')
        }
    }

    showUserDetail = (record) => {
        console.log('oooo111', record)
        this.openid = record.openid
        this.UserModalTitle = `用户: ${record.openid}`
        
        this.setState({
            showStatus: 1
        })
    }


    componentDidMount = () => {
        this.getAllUser()
    }

    render() {
        const { showUsers, showStatus } = this.state


        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};


        // 表格字段定义
        const columns = [
            {
                title: '用户OpenId',
                dataIndex: 'openid',
                width: 70,
                render: (a, record) => <a onClick={() => { this.showUserDetail(record) }}>{a}</a>,
            },
            {
                title: '微信昵称',
                dataIndex: 'nickName',
                width: 60
            },
            {
                title: '阅读本数',
                dataIndex: 'read_books',
                width: 30,
                sorter: (a, b) => a.read_books - b.read_books,
                sortOrder: sortedInfo.columnKey === 'read_books' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: '阅读页数',
                dataIndex: 'read_page',
                width: 40,
                sorter: (a, b) => a.read_page - b.read_page,
                sortOrder: sortedInfo.columnKey === 'read_page' && sortedInfo.order,
                ellipsis: true,
            },
            {
                title: '创建时间',
                dataIndex: 'createtime',
                width: 100
            },
            {
                title: '操作',
                render: (a, record) => <Button type='primary' size='small' style={{  fontSize: 10 }} onClick={() => { this.showUserDetail(record) }}>详情</Button>,
                width: 50
            }
        ]


        return (
            <div>
                <Search
                    placeholder="搜索Openid或用户昵称"
                    onChange={this.onChange}
                    style={{ width: 300, marginRight: 20, marginBottom: 20 }}
                />

                <Table
                    bordered={false}
                    dataSource={showUsers}
                    rowKey="id"
                    columns={columns}
                    onChange={this.handleChange}
                    pagination={{ defaultPageSize: 20, showQuickJumper: true, showSizeChanger: true }}
                />

                <Modal
                    title={this.UserModalTitle}
                    visible={showStatus === 1}
                    onOk={this.updateClassRoom}
                    // confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    destroyOnClose={true}
                    width={1000}
                >
                    <UserDetail openid={this.openid}  />
                </Modal>
            </div>
        )
    }
}
