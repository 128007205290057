import React from 'react'
import { Input, Button, Select, Table, message, Modal, Tag } from 'antd';
// import { PlusOutlined, ArrowRightOutlined, PhoneOutlined, TeamOutlined } from '@ant-design/icons'
import { reqAddBook, reqUpdateBook, reqBookShelfBook, reqDeleteBook, reqMultiAction } from '../../api/index'
import Book from '../book/book'
import UpdateBook from '../book/update-book'

import './book-list.less'
import './default.less'


const { Option } = Select;
const { Search } = Input

// 规则路由
export default class BookList extends React.Component {

    state = {

        showStatus: 0, // 0显示主页面，1显示新增门店，2显示修改门店
        confirmLoading: false,
        books: [],
        showBooks: [],
        selectStatus: -1,
        selectSave: [],
        url: "",
        selectedRowKeys: [],
        expandedRows: {},
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
      };

    classRoomStatusMap = [
        {
            'id': 0,
            'title': '停用',
        },
        {
            'id': 1,
            'title': '启用',
        }
    ]

    getLineCount = (text, width) => {
        const lineHeight = 20; // 每行的高度
        const element = document.createElement('div');
        element.textContent = text;
        element.style.maxHeight = 'none';
        element.style.overflow = 'visible';
        element.style.position = 'absolute';
        element.style.top = '-9999px';
        element.style.width = `${width}px`;
        element.style.whiteSpace = 'pre-wrap';
        document.body.appendChild(element);
        const lineCount = Math.ceil(element.clientHeight / lineHeight);
        document.body.removeChild(element);
        return lineCount;
    };

    handleExpandRow = (record) => {


        let { expandedRows } = this.state;

        let isExpanded = expandedRows[record.id];
        if(isExpanded==undefined) {
            isExpanded = false
        }


        expandedRows[record.id] = !isExpanded

        this.setState({
            expandedRows: expandedRows
        })
    };


    // 表格字段定义
    columns = [
        {
            title: 'id',
            dataIndex: 'id',
            width: 70
        },
        {
            title: '图片',
            dataIndex: 'img',
            render: (a, record) => <img src={record.img} alt="照片" />,
            width: 120
        },
        {
            title: '书名',
            dataIndex: 'name',
            width: 130
        },
        {
            title: '作者',
            dataIndex: 'author',
            width: 90
        },
        {
            title: '出版时间',
            dataIndex: 'press_date',
            width: 80
        },
        {
            title: '页数',
            dataIndex: 'page_count',
            width: 80
        },
        {
            title: '简介',
            dataIndex: 'abstract',
            width: 300,
            render: (text, record) => {
                const shouldCollapse = this.getLineCount(text, 300) > 4;
                const isExpanded = this.state.expandedRows[record.id];

                return (
                    <div>
                        <div
                            style={{
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                maxHeight: isExpanded ? 'none' : '3em',
                                overflow: isExpanded ? 'auto' : 'hidden',
                            }}
                        >
                            {text}
                        </div>
                        {shouldCollapse && (
                            <Button type="link" size="small" onClick={() => this.handleExpandRow(record)}>
                                {isExpanded ? '收起' : '展开'}
                            </Button>
                        )}
                    </div>
                );
            },

        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 80,
            render: (status, record) => {
                return <Tag color={status === 1 ? 'green' : 'red'} key={record.id} className="status-tag">
                    {status === 1 ? '展示中' : '下架中'}
                </Tag>
            },
        },
        {
            title: '创建时间',
            dataIndex: 'createtime',
            width: 140
        },
        {
            title: '操作',
            render: (a, record) => (
                <div>
                    <Button size='small' type='primary' style={{ marginRight: 20, fontSize: 10 }} onClick={() => { this.showUpdateBook(record) }}>修改</Button>
                    <Button size='small' type='danger' style={{ fontSize: 10 }} onClick={() => { this.showDeleteBook(record.id, record.name) }}>删除</Button>
                </div>
            ),
            // width: 150
        }
    ]

    handleCancel = () => {
        // 清除输入数据
        console.log("取消")
        this.form.resetFields()
        this.setState({
            showStatus: 0
        })
    }

    otherHandleCancel = () => {
        // 清除输入数据
        this.setState({
            showStatus: 0
        })
    }

    showAddStore = () => {
        // this.getStoreList()
        this.setState({
            showStatus: 1,  //新增
        })
    }

    showUpdateBook = (record) => {
        // this.getStoreList()
        this.record = record
        this.setState({
            showStatus: 2, // 修改
        })
    }

    showDeleteBook = (id, name) => {
        // this.getStoreList()
        // this.record = record
        this.deleteBookId = id
        this.deleteBookName = name
        this.setState({
            showStatus: 3 // 删除
        })
    }

    deleteBook = async () => {
        const id = this.deleteBookId
        const result = await reqDeleteBook(id)
        if (result.status === 0) {
            this.getBookShelfBook(false)
            setTimeout(() => {
                this.orderBook()
            }, 1000)
            this.setState({
                showStatus: 0 // 删除
            })


        } else {
            message.error('删除书籍失败！')
        }

    }



    changeShowImgUrl = (url, id = -1) => {
        if (id !== -1) {
            this.setState({
                url,
                book_id: id
            })
        } else {
            this.setState({
                url
            })
        }
    }

    orderBook = () => {
        const { books, selectStatus, inputSearchValue } = this.state
        let showBooks = books

        if (selectStatus !== -1) {
            // 选中了
            showBooks = books.filter((item, index) => {
                return item.status === selectStatus
            })
        }

        let filteredList = showBooks
        if (inputSearchValue !== "" && inputSearchValue !== undefined) {
            filteredList = showBooks.filter((item, key) => {
                const name = item.name
                const author = item.author;

                return name.indexOf(inputSearchValue) !== -1 || author.indexOf(inputSearchValue) !== -1;

            });
        }
        console.log("filteredList", filteredList)

        this.setState({
            showBooks: filteredList
        })
    }

    getBookShelfBook = async (init = true) => {
        const result = await reqBookShelfBook()
        if (result.status === 0) {
            if (init) {
                this.setState({
                    books: result.data,
                    showBooks: result.data
                })
            } else {
                this.setState({
                    books: result.data,
                })
            }

        } else {
            message.error('获取书籍列表失败！')
        }
    }

    addBook = () => {
        this.setState({
            confirmLoading: true
        })

        this.form.validateFields(async (err, values) => {
            if (!err) {

                const { url } = this.state
                if (url === "") {
                    message.error("图片上传未完成！")
                    this.setState({
                        confirmLoading: false
                    })
                    return
                }
                // 收集数据并提交添加分类的请求
                // const {parentId, categoryName} = this.form.getFieldsValue()

                const { name, author, page_count, abstract, status, press_date } = values



                const result = await reqAddBook(
                    name, author, page_count, abstract, status, press_date, url
                ).catch(() => {
                    return
                })
                if (result.status === 0) {
                    message.success('添加书籍成功!')
                    this.form.resetFields()
                    this.setState({
                        showStatus: 0
                    })
                } else {
                    message.error(`添加书籍失败: ${result.msg}`)
                    return
                }
                this.setState({
                    confirmLoading: false
                })
                this.getBookShelfBook(false)
                setTimeout(() => {
                    this.orderBook()
                }, 1000)

            } else {
                message.error("输入信息有误！")
            }
        })
        this.setState({
            confirmLoading: false,
        })

    }


    editBook = () => {
        this.setState({
            confirmLoading: true
        })

        this.form.validateFields(async (err, values) => {
            if (!err) {

                const { url, book_id } = this.state
                if (url === "") {
                    message.error("图片上传未完成！")
                    this.setState({
                        confirmLoading: false
                    })
                    return
                }

                // 隐藏确认框
                this.setState({
                    showStatus: 0
                })

                // 收集数据并提交添加分类的请求
                // const {parentId, categoryName} = this.form.getFieldsValue()

                const { name, author, page_count, abstract, status, press_date } = values
                // const bookshelf_status = status == true ? 1 : 0

                this.form.resetFields()

                const result = await reqUpdateBook(
                    book_id, name, author, page_count, abstract, status, press_date, url
                )
                if (result.status === 0) {
                    message.success('更新书籍成功!')
                    this.setState({
                        showStatus: 0
                    })
                } else {
                    message.error(`更新书籍失败: ${result.msg}`)
                }
                this.setState({
                    confirmLoading: false
                })
                this.getBookShelfBook(false)
                setTimeout(() => {
                    this.orderBook()
                }, 1000)
            } else {
                message.error("输入信息有误！")
            }
        })
        this.setState({
            confirmLoading: false,
            // url: ""
        })
    }



    storeSelectOnFocus = () => {
        this.setState({
            storeSelectLoading: true
        })
        this.getStoreList()
        this.setState({
            storeSelectLoading: false
        })
    }


    storeOnchange = (value) => {
        const { classRoomList } = this.state
        this.setState({
            storeIdSelect: value
        }, () => {
            this.classStatusFilter(classRoomList)
        })
    }

    bookStatusOnChange = (value) => {
        const { books } = this.state
        if (value === undefined) {
            this.setState({
                showBooks: books,
                selectStatus: -1,
                selectSave: [],
                selectValue: value
            })
            return
        }

        const showBooks = books.filter((item, index) => {
            console.log(item, item.status, item.status === value)
            return item.status === value
        })
        this.setState({
            showBooks: showBooks,
            selectStatus: value,
            selectSave: showBooks,
            selectValue: value
        })
    }

    searchOnChange = (e) => {
        let input = e.target.value.trim()
        const { books, selectStatus, selectSave } = this.state

        let b

        if (selectStatus !== -1) {
            b = selectSave
        } else {
            b = books
        }

        if (input === "") {
            this.setState({
                showBooks: b,
                inputSearchValue: "",
            })
            return
        }

        const filteredList = b.filter((item, key) => {
            const name = item.name
            const author = item.author;

            return name.indexOf(input) !== -1 || author.indexOf(input) !== -1;

        });


        this.setState({
            showBooks: filteredList,
            inputSearchValue: input,
        })
    }

    componentDidMount = () => {
        // this.getClassRoomList()
        this.getBookShelfBook()
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
      };

    // 批量修改showBooks 和 books中的status
    updateReqAfterBooksStatus = (ids, action) => {
        let status = 0
        if(action=="up") {
            status = 1
        }
        let newShowBooks = []
        let newBooks = []
        let {showBooks, books} = this.state
        showBooks.map((item) => {
            if(ids.indexOf(item.id)!==-1) {
                const newItem = item
                newItem.status = status
                newShowBooks.push(newItem)
            } else {
                newShowBooks.push(item)
            }
        })

        books.map((item) => {
            if(ids.indexOf(item.id)!==-1) {
                const newItem = item
                newItem.status = status
                newBooks.push(newItem)
            } else {
                newBooks.push(item)
            }
        })

        this.setState({
            showBooks: newShowBooks,
            books: newBooks,
            selectedRowKeys: []
        })
    }

    multiAction = async (action) => {
        const {selectedRowKeys} = this.state
        const result = await reqMultiAction(action, selectedRowKeys)
        if (result.status === 0) {
            message.success('批量操作成功!')
            // 此时将更新的状态的数据，在state里面的也要改掉
            // showBooks 和 books
            this.updateReqAfterBooksStatus(selectedRowKeys, action)
        } else {
            message.error(`批量操作失败: ${result.msg}`)
        }



        // 如果执行成功，
            // 清理选中的数据
            // 刷新页面


    }

    render() {
        const { showBooks, showStatus, confirmLoading, selectedRowKeys } = this.state
        const name = this.deleteBookName
        let buttonStatus
        if(selectedRowKeys.length==0) {
            buttonStatus = true
        } else {
            buttonStatus = false
        }

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
          };

        return (
            <div>
                <Button type="primary" style={{ marginBottom: 20, marginRight: 20 }} onClick={this.showAddStore}>新增书籍</Button>
                <Select
                    placeholder="上架状态"
                    allowClear
                    style={{ width: 130, marginRight: 20 }} onChange={this.bookStatusOnChange}>
                    <Option value={1} key={1}>展示中</Option>
                    <Option value={0} key={0}>已下架</Option>
                </Select>

                <Search
                    placeholder="输入书籍名称或作者搜索"
                    // enterButton="Search"
                    allowClear
                    onChange={this.searchOnChange}
                    style={{ width: 300, marginRight: 20 }}
                />

                <Button type="primary" disabled={buttonStatus} style={{ marginBottom: 20, marginRight: 20 }} onClick={() => this.multiAction('up')}>批量上架</Button>
                <Button type="danger" disabled={buttonStatus} style={{ marginBottom: 20, marginRight: 20 }} onClick={() => this.multiAction('down')}>批量下架</Button>


                <Table
                    bordered={false}
                    dataSource={showBooks}
                    rowKey="id"
                    columns={this.columns}
                    rowSelection={rowSelection}
                    pagination={{ defaultPageSize: 10, showQuickJumper: true, showSizeChanger: true }}
                />

                <Modal
                    title="添加书籍"
                    visible={showStatus === 1}
                    onOk={this.addBook}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    destroyOnClose={true}
                    width={1000}
                    style={{ top: 10 }}
                >
                    <Book setForm={(form) => { this.form = form }} setUrl={this.changeShowImgUrl} />
                </Modal>

                <Modal
                    title="更新书籍"
                    visible={showStatus === 2}
                    onOk={this.editBook}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    maskClosable={false}
                    destroyOnClose={true}
                    width={1000}
                    style={{ top: 10 }}
                >
                    <UpdateBook record={this.record} setForm={(form) => { this.form = form }} setUrl={this.changeShowImgUrl} />
                </Modal>

                <Modal
                    title="删除书籍"
                    visible={showStatus === 3}
                    onOk={this.deleteBook}
                    confirmLoading={confirmLoading}
                    onCancel={this.otherHandleCancel}
                    maskClosable={false}
                    destroyOnClose={true}>
                    <p>确认删除：{name} 吗？</p>
                </Modal>

                {/* <Modal
                title="修改教室"
                visible={showStatus===2}
                onOk={this.updateClassRoom}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                >
                    <AddClassRoom setForm={(form) => {this.form=form}} record={this.record} storeList={storeList}/>
                </Modal> */}
            </div>
        )
    }
}
