// const menuList = [
//     {
//         title: '首页', // 菜单标题名称
//         key: '/home', // 对应的path
//         icon: 'home', // 图标名称
//     },
//     {
//         title: '商品',
//         key: '/products',
//         icon: 'appstore',
//         children: [ // 子菜单列表
//             {
//                 title: '品类管理',
//                 key: '/category',
//                 icon: 'bars'
//             },
//             {
//                 title: '商品管理',
//                 key: '/product',
//                 icon: 'tool'
//             },
//         ]
//     },
//     {
//         title: '会员管理',
//         key: '/user',
//         icon: 'user'
//     },
//     {
//         title: '角色管理',
//         key: '/role',
//         icon: 'safety',
//     },
//     {
//         title: '图形图表',
//         key: '/charts',
//         icon: 'area-chart',
//         children: [
//             {
//                 title: '柱形图',
//                 key: '/charts/bar',
//                 icon: 'bar-chart'
//             },
//             {
//                 title: '折线图',
//                 key: '/charts/line',
//                 icon: 'line-chart'
//             },
//             {
//                 title: '饼图',
//                 key: '/charts/pie',
//                 icon: 'pie-chart'
//             },
//         ]
//     },
// ]

const menuList = [
    {
        title: '书籍管理',
        key: '/book',
        icon: 'book'
    },
    {
        title: '图书统计',
        key: '/stat',
        icon: 'book'
    },
    {
        title: '用户列表',
        key: '/user',
        icon: 'user'
    },
]


export default menuList