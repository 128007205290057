import React from 'react'
import './book.less'
import { reqUploadImg } from '../../api/index'


import {
    Form,
    Input,
    InputNumber,
    Switch,
    Button,
    Upload,
    Icon,
    message
} from 'antd';

const { TextArea } = Input;

class Book extends React.Component {
    state = {
        choiceImg: false,
        url: ""
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
            console.log("valuesvalues", values)
        });
    };

    normFile = e => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    handleCustomRequest = (options) => {
        this.setState({
            choiceImg: true
        })
        const { onSuccess, onError, file } = options

        reqUploadImg(file).then((res) => {
            if(res.code===0){
                onSuccess();
                const url = res.data
                this.setState({
                    url
                })
                this.props.setUrl(url)
            } else {
                onError();
            }
        }).catch((err) => {
            onError();
            message.error("上传图片出错，", err)
        })

        // this.uploadImg(file)
    }   

    onRemove = () => {
        console.log("onRemove")
        this.setState({
            choiceImg: false,
            url: ""
        })
        this.props.setUrl("")
    }

    handleBeforeUpload = (file, fileList) => {
        this.setState({
            choiceImg: true
        })

        return false

    //     console.log(file, fileList)
    //     if (fileList.length > 1) {
    //       console.log('只能上传一个文件！');
    //       return false;
    //     } else {
    //         this.setState({
    //             choiceImg: true
    //         })
    //     }
    //     return true;
    };

    componentWillMount() {
        // 将form对象通过setForm方法传递给父组件
        this.props.setForm(this.props.form)
    }

    render() {
        const {url} = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 10 },
        };
        const bookFormItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        const authorFormItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 10 },
        };
        const pressDateFormItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 8 },
        };
        const abstractDateFormItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        return (

            <div className='book'>
                <div className='left'>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                        <Form.Item {...bookFormItemLayout} label="书名">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: '书名必须输入' }],
                            })(
                                <Input placeholder="请输入书名" />,
                            )}
                        </Form.Item>

                        <Form.Item {...authorFormItemLayout} label="作者">
                            {getFieldDecorator('author', {
                                rules: [{ required: true, message: '作者必须输入，不知道请输入"未知"' }],
                            })(
                                <Input placeholder="请输入作者" />,
                            )}
                        </Form.Item>

                        <Form.Item {...pressDateFormItemLayout} label="出版时间">
                            {getFieldDecorator('press_date', {
                                rules: [
                                    { required: true, message: '出版年月必须输入' },
                                    { pattern: /^([1-9]\d{3})(0[1-9]|1[0-2])$/, message: '出版年月格式示例：202001'}
                                ],
                            })(
                                <Input placeholder="请输入出版年月" />,
                            )}
                        </Form.Item>


                        <Form.Item label="页数">
                            {getFieldDecorator('page_count', {
                                rules: [
                                    { required: true, message: '请输入页数' },
                                    { pattern: /^[1-9]\d*$/, message: '页数必须是数字' }

                                ],
                            })(<InputNumber placeholder="输入页数" />)}
                            <span className="ant-form-text"> 页</span>
                        </Form.Item>

                        <Form.Item label="是否上架">
                            {getFieldDecorator('status', { valuePropName: 'checked', initialValue: true })(<Switch />)}
                        </Form.Item>


                        <Form.Item label="封面图" extra="">
                            {getFieldDecorator('upload', {
                                valuePropName: 'fileList',
                                getValueFromEvent: this.normFile,
                                rules: [{ required: true, message: '图片必须选择' }],

                            })(
                                <Upload customRequest={this.handleCustomRequest}  onRemove={this.onRemove}  name="logo" accept=".jpg, .jpeg, .png, .gif, .bmp, .webp, .tiff, .tif, .svg, .ico, .psd, .ai" listType="picture">
                                    <Button disabled={this.state.choiceImg}>
                                        <Icon type="upload" /> 上传封面图片
                                    </Button>
                                </Upload>,
                            )}
                        </Form.Item>

                        <Form.Item {...abstractDateFormItemLayout} label="简介">
                            {getFieldDecorator('abstract', {
                                rules: [{ required: true, message: '简介必须输入' }],
                            })(
                                <TextArea 
                                autoSize={{ minRows: 8 }}

                                placeholder="请输入简介内容" />,
                            )}
                        </Form.Item>

                        {/* <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                            <Button type="primary" htmlType="submit">
                                添加此书
                            </Button>
                        </Form.Item> */}
                    </Form>
                </div>
                <div className='right'>
                    {
                        url? ( <img className='img' style={{display: 'block'}} src={url} />
                        ): (
                            <img className='img' style={{display: 'none'}} src={url} />
                        )
                    }
                </div>
            </div>

        );
    }
}



export default Form.create()(Book)