/*
能发送异步ajax请求的函数模块
封装axios库
返回值是promise对象
1. 优化: 统一处理请求异常
    在外层包一个自己创建的promise对象
    在请求出错时,不去reject(error),而是显示错误提示

2. 优化2: 异步得到的不是response, 而是response.data
    在请求成功resolve时: resolve(response.data)
*/
import { message } from 'antd'
import axios from 'axios'
import qs from 'qs'


import memoryUtils from '../utils/memoryUtils'
// import removeUser from '../utils/storageUtils'


// axios.defaults.headers = {
//     'Content-type': 'application/x-www-form-urlencoded'
// } //配置请求头



let isTokenExprise = false

axios.interceptors.response.use(config=>{
    return config
}, err=>{
    if(err.response.status===401){
        if(!isTokenExprise){
            isTokenExprise = true
            message.warning('身份信息已过期，即将重新登录......')
            setTimeout(()=>{
                window.location.replace('/login')
            }, 1500)
        }
    }
    console.log('拦截器响应错误非401， isTokenExprise=> ', isTokenExprise)

})

// const openNotification = () => {
//     notification.open({
//       message: 'Notification Title',
//       description:
//         '身份信息已过期，请重新登陆！',
//       onClick: () => {
//         console.log('身份信息已过期，请重新登陆！');
//       },
//     });
//   };


export default function ajax(url, data={}, method='GET', headers={}, call) {

    if(!headers.token){
        const token = memoryUtils.user.token
        headers.token = token
    }

    return new Promise((resolve, reject) => {

        let promise
        // 1. 执行异步ajax请求
        if(method==='GET'){
            promise = axios.get(url, {
                params: data,
                headers: headers,
            })
        } else if(method==='POST') {
            if(!headers['Content-Type']){
                headers['Content-type'] = 'application/x-www-form-urlencoded'
                data = qs.stringify(data)
            }

            promise = axios.post(url,
                data,
                {headers})  
        }
        // 2. 如果成功了,调用resolve(value)
        promise.then(response => {
            // 如果成功了,调用resolve(value)
            resolve(response.data)
        // 3. 如果失败了,不调用reject(reason), 而是提示异常信息
        }).catch(error => {
            // reject(reason)
            if(!isTokenExprise){
                message.error('请求出错了: ' + error.message)
                if(call){
                    call()
                }
            }
        })
    })
}


