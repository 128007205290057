import React, {Component} from 'react'
import { Layout } from 'antd';
import {Route, Switch, Redirect} from 'react-router-dom'


import LeftNav from '../../components/letf-nav'
import Header from '../../components/header/header'
import memoryUtils from '../../utils/memoryUtils'
import MyBreadcrumb from '../../components/breadcrumb/breadcrumb'
import BookList from '../book-list/book-list'
import User from '../user/user'
import Stat from '../stat/stat'
import { collapsedStorage } from '../../utils/storageUtils'

import './admin.less'



const { Sider, Content } = Layout;

// 登录的路由组件
export default class Admin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            collapsed: collapsedStorage.getCollapsed(),
        }
    }


    
    toggle = () => {
        const currentStatus = this.state.collapsed
        collapsedStorage.saveCollapsed(!currentStatus)
        this.setState({
            collapsed: !currentStatus,
        });
    };


    render() {
        const user = memoryUtils.user
        console.log("这是内存中的用户", user)
        // 如果内存中没有存储user ==> 当前没有登录
        if(!user || !user.token) {
            // 自动跳转到登录(在render()中)
            console.log('redirect /login')
            return <Redirect to='/login' />
        }

        return (
            <Layout style={{height: '100%'}}>
                <Sider className='admin-side' trigger={null} collapsible collapsed={this.state.collapsed}>
                    <LeftNav fontSize={this.state.collapsed}/>
                </Sider>
                    <Layout>
                        <Header toggle={this.toggle} collapsed={this.state.collapsed}/>
                        
                        <Content className='content'>
                            <MyBreadcrumb />
                            <Switch>
                                <Route path='/book' component={BookList} />
                                <Route path='/user' component={User} />
                                <Route path='/stat' component={Stat} />
                                <Redirect to='/user' />
                            </Switch>
                        </Content>
                        {/* <Footer style={{textAlign: 'center', color: '#ccc'}}>推荐使用谷歌浏览器,获得最佳体验</Footer> */}
                    </Layout>
            </Layout>
        )
    }
}