/*
要求: 能根据接口文档定义接口请求
包含应用中所有接口请求函数的模块
每个函数的返回值都是promise
*/

import ajax from './ajax'
import ajax_upload from './ajax_upload'
// import jsonp from 'jsonp'


const BASE = 'https://daka.fojiaonet.cn:9999/weapp/note/v5001'
// const BASE = '/weapp/note/v5001'


// 登录
export const reqLogin = (username, password, resetLoginLoading) => ajax(BASE + '/admin/login', {username, password}, 'POST', {}, resetLoginLoading)
export const reqBookTopList = () => ajax(BASE + '/admin/book_top', {}, 'GET')
export const reqAllUser = () => ajax(BASE + '/admin/get_all_user', {}, 'GET')
export const reqUserBookList = (openid) => ajax(BASE + '/admin/get_user_book_list', {openid}, 'POST')
export const reqBookShelfBook = () => ajax(BASE + '/admin/get_bookshelf_book', {}, 'GET')
export const reqUploadImg = (file) => ajax_upload(BASE + '/admin/upload_img', file, 'POST')
export const reqDeleteBook = (id) => ajax(BASE + '/admin/delete_book', {id}, 'POST')
export const reqAddBook = (name, author, page_count, abstract, status, press_date, img) => ajax(BASE + '/admin/add_book', {name, author, page_count, abstract, status, press_date, img}, 'POST')
export const reqUpdateBook = (id, name, author, page_count, abstract, status, press_date, img) => ajax(BASE + '/admin/update_book', {id, name, author, page_count, abstract, status, press_date, img}, 'POST')
export const reqMultiAction = (action, ids) => ajax(BASE + '/admin/multi_action', {action, ids}, 'POST', {'Content-Type': 'application/json'})

// // jsonp请求的接口函数
// export const reqWeather = (city) => {

//     return new Promise((resolve, reject) => {

//         const url = `http://api.map.baidu.com/telematics/v3/weather?location=${city}&output=json&ak=3p49MVra6urFRGOT9s8UBWr2`
//         jsonp(url, {}, (err, data) => {
//             console.log('jsonp()', err, data)
//             if (!err && data.status==='success'){
//                 const {dayPictureUrl, weather} =  data.results[0].weather_data[0]
//                 resolve({dayPictureUrl, weather})
//             } else {
//                 // 如果失败了
//                 message.error("获取天气信息失败")
//             }
//         })
//     })

// }
