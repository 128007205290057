import React, { Component } from 'react'
import {
    Form,
    Icon,
    Input,
    Button,
    message
} from 'antd';
// import {Redirect} from 'react-router-dom'

import './login.less'
import logo from '../../assets/images/logo.png'
import {reqLogin} from '../../api'
import memoryUtils from '../../utils/memoryUtils'
import storageUtils from '../../utils/storageUtils'


// 不能写在import之前
// const Item = Form.Item


// 登录的路由组件
class Login extends Component {
    state = {
        showForm: true, // True为login，false为重置密码
        organiz_data: [],
        loading: false
    }

    resetLoginLoading = () => {
        console.log('resetLoginLoading')
        this.setState({
            loading: false
        })
    }

    handleSubmit = (event) => {
        // console.log(this.username.state.value, this.password.state.value)

        //阻止事件的默认行为, 不提交表单
        event.preventDefault()

        this.setState({
            loading: true
        })
        console.log("开始按钮加载")

        // 进行表单所有控件的校验
        this.props.form.validateFields(async (err, values) => {
            if(!err) {
                //校验成功
                console.log(values)
                const {mobile, password} = values
                let result
                try {
                    result = await reqLogin(mobile, password, this.resetLoginLoading)  // {status: 0, data:{user数据}} or {status:1, msg:'错误原因'}
                } catch {
                    console.log('result catch')
                }
                
                // console.log('请求成功 ', response)
                if(result.status===0){ // 登录成功
                    // 提示登录成功
                    message.success('登录成功!')

                    // 保存user
                    const data = result
                    memoryUtils.user = data.data // 保存在内存中
                    storageUtils.saveUser(data.data ) // 保存在local中
                    console.log("用户存储成功")
                    // const organiz_data = data.data.organiz_data
                    // console.log('data.organiz_data', organiz_data)
                    // if(organiz_data===""){
                    this.props.history.replace('/') // 跳转到后台管理界面(不需要再回退到登录所以用replace, 需要回退则使用push)
                    // } else {
                        // this.setState({
                            // showForm: false,
                            // organiz_data: organiz_data
                        // })
                    // }

                } else { // 登录失败
                    // 提示错误信息
                    console.log('登录失败')
                    message.error(result.msg)
                }

            } else {
                //校验失败
                console.log('校验失败', err)
            }

            console.log("取消按钮加载")
            this.setState({
                loading: false,
            })
        })

    }


    // 自定义表单的校验规则
    validator = (rule, value, callback) => {
        // console.log(rule, value)
        const length = value && value.length
        const pwdReg = /^[a-zA-Z0-9_]+$/
        if (!value) {
            // callback 如果不传参代表校验成功，如果传参代表校验失败，并且会提示错误
            callback('必须输入密码')
        } else if (length < 4) {
            callback('密码必须大于4 位')
        } else if (length > 12) {
            callback('密码必须小于12 位')
        } else if (!pwdReg.test(value)) {
            callback('密码必须是英文、数组或下划线组成')
        } else {
            callback() // 必须调用callback
        }
    }

    changeShowForm = () => {
        console.log('change form')
        this.setState({
            showForm: !this.state.showForm
        })
    }

    render() {

        // 如果用户已经登录,自动跳转到管理界面
        // const user = memoryUtils.user
        // if(user && user.username) {
        //     return <Redirect to='/' />
        // }

        //得到具有功能强大的form对象
        const form = this.props.form

        const { getFieldDecorator } = form

        const rules = [
            { required: true, whitespace: true,  message: '用户名必须输入' },
            // { len: 11, message: '手机号必须是11位!' },
            // { pattern: /^[0-9]+$/, message: '手机号必须是数字'}
        ]

        const {organiz_data, loading} = this.state

        // return <ResetPasswdForm />
        // if(!this.state.showForm){
        //     return (
        //         <div>
        //             <div className="org-choice">
        //                 请选择机构
        //             </div>
        //             <div className="org-prompt">
        //                 您的账号下有{organiz_data.length}家机构
        //             </div>
        //             {this.state.organiz_data.map((data) => (
        //                 <Organiz organiz_data={data} setupOrganiz={this.setupOrganiz} key={data.organization_id}/>
        //             ))}
        //         </div>
                
        //     )
                
        // }

        return (
            <div className="login-root">

            <div className="form-wrap">
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <Form.Item className="login-form-header">
                        <div className="login-title">
                            享读打卡
                        </div>
                        <div className="login-title-intro">
                            阅读打卡小程序
                        </div>
                    </Form.Item>

                    <Form.Item className="login-form-mobile">
                        {getFieldDecorator('mobile', {
                            rules: [
                                { required: true, message: '用户名必须输入!' },
                                // { len: 11, message: '手机号必须是11位!' },
                                // { pattern: /^[0-9]+$/, message: '手机号必须是数字'}
                            ],
                            
                        })(
                            <Input
                            className="form-input"
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="请输入手机号"
                            />,
                        )}
                    </Form.Item>

                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: '请输入密码!' }],
                        })(
                            <Input.Password
                            className="form-input"
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="请输入密码"
                            />,
                        )}
                    </Form.Item>

                    <div className="login-line-div">
                        <div className="login-line"></div>
                    </div>
                    

                    <Form.Item className="login-form-button-parent">
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} >
                            登录
                        </Button>
                    </Form.Item>

                </Form>
            </div>

            </div>
        )
    }
}



const WraLogin = Form.create()(Login)
export default WraLogin

/*
维持登录和自动登录
1. 登录后,刷新依然是已登录状态(维持登录)
2. 登录后,关闭浏览器后打开浏览器依然是已登录状态(自动登录)
3. 登陆后, 访问登录路径自动跳转到管理界面
*/


/*
async和await
1. 作用?
    不用再使用.then来指定成功或者失败的回调函数
    以同步编码(没有回调函数)方式实现异步流程
2. 哪里写await
在返回promise的表达式左侧写await:不想要promise, 想要promise异步执行成功的value数据
3. 哪里写async
    await所有函数(最近的)定义的左侧
*/